import moment from "moment";
import { Room_type } from "../../utils/Constants";

export const ChatListCard = ({
  id,
  selectedChatId,
  item,
  activeIndex,
  index,
  onClick,
  isActive = false,
  groupchat = false,
}) => {
  function getTimeDisplay(apiTime) {
    const currentTime = moment();
    const parsedTime = moment(apiTime);
    if (parsedTime.isSame(currentTime, "day")) {
      return parsedTime.format("hh:mm A");
    } else if (
      parsedTime.isSame(currentTime.clone().subtract(1, "day"), "day")
    ) {
      return "Yesterday";
    } else {
      return parsedTime.format("DD/MM/YYYY");
    }
  }

  return (
    <div className="chat-listing-card-wrap">
      <div
        className={
          id === selectedChatId
            ? "chat-listing-card active"
            : "chat-listing-card"
        }
        onClick={onClick}
      >
        <div className="content">
          {item?.roomType === Room_type.G ? (
            <div className="group-chat-wrap">
              {item?.roomImageDetails?.map((it, index) => {
                if (it?.media) {
                  const isVideo = /\.(mp4|mov|avi|gif)$/.test(
                    it?.media?.toLowerCase()
                  );
                  return isVideo ? (
                    <video
                      className={`user-img-${index + 1}`}
                      autoPlay
                      playsInline
                      loop
                      muted
                      src={it?.media}
                    >
                      <source src={"" || ""} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      alt=""
                      src={it?.media ? it?.media : "/static/connect/avatar.svg"}
                      style={{
                        border: "1px solid #dcdcdc",
                      }}
                      className={`user-img-${index + 1}`}
                    />
                  );
                } else {
                  return it?.profilePictureExtension?.includes("video") ? (
                    <video
                      className={`user-img-${index + 1}`}
                      autoPlay
                      playsInline
                      loop
                      muted
                      src={it?.profilePicture}
                    >
                      <source src={"" || ""} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      alt=""
                      src={
                        it?.profilePicture
                          ? it?.profilePicture
                          : "/static/connect/avatar.svg"
                      }
                      style={{
                        border: "1px solid #dcdcdc",
                      }}
                      className={`user-img-${index + 1}`}
                    />
                  );
                }
              })}
            </div>
          ) : item?.roomType === Room_type.L ||
            item?.roomType === Room_type.E ? (
            /\.(mp4|mov|avi|gif)$/.test(
              item?.roomImageDetails?.[0]?.media?.toLowerCase() ||
                item?.roomImageDetails?.[0]?.thumbnail?.toLowerCase()
            ) ? (
              <video
                className={`user-img`}
                autoPlay
                playsInline
                loop
                muted
                src={
                  item?.roomImageDetails?.[0]?.media ||
                  item?.roomImageDetails?.[0]?.thumbnail
                }
              >
                <source src={"" || ""} type="video/mp4" />
              </video>
            ) : (
              <img
                alt=""
                src={
                  item?.roomImageDetails?.[0]?.media
                    ? item?.roomImageDetails?.[0]?.media
                    : item?.roomImageDetails?.[0]?.thumbnail
                    ? item?.roomImageDetails?.[0]?.thumbnail
                    : "/static/connect/avatar.svg"
                }
                style={{
                  border: "1px solid #dcdcdc",
                }}
                className="user-img"
              />
            )
          ) : item?.roomImageDetails?.[0]?.profilePictureExtension?.includes(
              "video"
            ) ? (
            <video
              className={`user-img`}
              autoPlay
              playsInline
              loop
              muted
              src={item?.roomImageDetails?.[0]?.profilePicture}
            >
              <source src={"" || ""} type="video/mp4" />
            </video>
          ) : (
            <img
              alt=""
              src={
                item?.roomImageDetails?.[0]?.profilePicture
                  ? item?.roomImageDetails?.[0]?.profilePicture
                  : item?.roomImageDetails?.[0]?.media
                  ? item?.roomImageDetails?.[0]?.media
                  : item?.roomImageDetails?.[0]?.thumbnail
                  ? item?.roomImageDetails?.[0]?.thumbnail
                  : "/static/connect/avatar.svg"
              }
              style={{
                border: "1px solid #dcdcdc",
              }}
              className="user-img"
            />
          )}
          <div>
            <div className="title">{item?.name}</div>
            {item?.status === "CM" && (
              <div className="desc">{item?.message}</div>
            )}
          </div>
          {item?.status === "CM" && (
            <div className="time">
              {item?.messageTime ? getTimeDisplay(item?.messageTime) : ""}
            </div>
          )}
          {item?.status === "CM" && !item?.isRead ? (
            <div className="active-dot">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle
                  cx="4"
                  cy="4"
                  r="4"
                  fill="url(#paint0_linear_4235_143086)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_4235_143086"
                    x1="-1.45641"
                    y1="-1.18957"
                    x2="2.08174"
                    y2="14.2197"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#EE7830" />
                    <stop offset="1" stop-color="#EE762F" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          ) : null}
        </div>
      </div>
      <div className="divider" />
    </div>
  );
};
