import React from "react";

type Props = {
  [key: string]: any;
};

const JoinTag = (props: Props) => {
  return (
    <div className="amount_wrap">{'Join'}</div>
  );
};

export default JoinTag;
