import CustomRadio from "../../CustomRadio";
import CustomWeekDay from "../../CustomWeekDay";
import CustomNumberInput from "../../CustomNumberInput";
import "./style.scss";
import MultiDatePicker from "../../DatePicker/MultiDatePicker/index.tsx";
import CustomSingleSelect from "../../CustomSingleSelect";
import CustomMultiSelect from "../../CustomMultiSelect";
import SingleDatePicker from "../../DatePicker/SingleDatePicker/index.tsx";
import {
  DATE_FORMAT,
  eventData,
  radioData,
  WEEKLY_FORM_KEYS,
} from "../../../constants/helperScheduleDates";
import { recurFrequency } from "../../../utils/Constants";

const WeekDays = [
  {
    label: "Sun",
    value: "Sun",
  },
  {
    label: "Mon",
    value: "Mon",
  },
  {
    label: "Tue",
    value: "Tue",
  },
  {
    label: "Wed",
    value: "Wed",
  },
  {
    label: "Thu",
    value: "Thu",
  },
  {
    label: "Fri",
    value: "Fri",
  },
  {
    label: "Sat",
    value: "Sat",
  },
];

const MonthData = [
  {
    label: "1st",
    value: 1,
  },
  {
    label: "2nd",
    value: 2,
  },
  {
    label: "3rd",
    value: 3,
  },
  {
    label: "4th",
    value: 4,
  },
  {
    label: "5th",
    value: 5,
  },
];

export default function WeeklyListing({ setValue, getValues, watch }) {
  const customEventDate = (name: any, val: any) => {
    setValue(name, val);
    if (getValues(name).length > 1) {
      // setValue(WEEKLY_FORM_KEYS.END_EVENT_TYPE, eventData[0].value);
      setValue(WEEKLY_FORM_KEYS.REPEAT_TYPE, recurFrequency[2]);
    }
  };

  const renderWeekFields = () => {
    return (
      <div className="mb-3">
        <CustomWeekDay
          label="Select recurrence days"
          data={WeekDays}
          name="weekDays"
          getValues={getValues}
          handleSetValue={setValue}
        />
      </div>
    );
  };

  const handleEventTypeChange = (name: any, val: any) => {
    setValue(name, val);
    setValue("eventDate", []);
    watch("eventDate", []);
  };

  const renderMonthFields = () => {
    return (
      <>
        <div className="mb-3 d-flex flex-column flex-sm-row justify-content-between align-items-end gap-3">
          <div className=" w-100">
            <CustomWeekDay
              label="On every"
              data={MonthData}
              name="monthOnEvery"
              getValues={getValues}
              handleSetValue={setValue}
              isDisabled={getValues(WEEKLY_FORM_KEYS.EVENT_DATE).length > 1}
            />
          </div>
          <div className="monthDay">
            <CustomMultiSelect
              label="of the month"
              name="monthOnDays"
              watch={watch}
              getValues={getValues}
              handleSetValue={setValue}
              isDisabled={getValues(WEEKLY_FORM_KEYS.EVENT_DATE).length > 1}
            />
          </div>
        </div>
        {getValues(WEEKLY_FORM_KEYS.EVENT_DATE).length > 1 ? (
          <div className="footer-note d-flex gap-2 mb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-info-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
            </svg>
            Multiple dates have been selected, so this feature cannot be applied
            to the recurring event.
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderRepeatType = (type: any) => {
    switch (type) {
      case "Week":
        return renderWeekFields();
      case "Month":
        return renderMonthFields();
    }
  };

  const renderRecurringFields = () => {
    return (
      <>
        <div className=" d-flex align-items-center gap-3 mb-3">
          <CustomNumberInput
            customClass="repeatInEvery"
            label="Repeat in every"
            name="repeatInEvery"
            watch={watch}
            handleSetValue={setValue}
            min={1}
            isReadOnly
            isDisabled={getValues(WEEKLY_FORM_KEYS.EVENT_DATE).length > 1}
          />
          <CustomSingleSelect
            placeholder="Select Type"
            name="repeatType"
            dropdownData={recurFrequency}
            watch={watch}
            handleSetValue={setValue}
            getValues={getValues}
            isDisabled={getValues(WEEKLY_FORM_KEYS.EVENT_DATE).length > 1}
          />
        </div>

        {renderRepeatType(getValues("repeatType"))}

        <div className="end-event-title">End event</div>
        <div className=" d-flex mb-3">
          <CustomRadio
            row={false}
            radio_wrap_class="gap-3"
            radioData={eventData}
            name="endEventType"
            watch={watch}
            handleSetValue={setValue}
            // isDisabled={getValues(WEEKLY_FORM_KEYS.EVENT_DATE).length > 1}
          />
          <div className=" d-flex flex-column gap-3">
            <CustomNumberInput
              endAdornmentText="occurences"
              name="endAfterVal"
              watch={watch}
              handleSetValue={setValue}
              min={1}
              max={365}
              isDisabled={
                getValues("endEventType") === "end-by" ||
                getValues("endEventType") === ""
              }
            />
            <SingleDatePicker
              isDisabled={
                getValues("endEventType") === "end-after" ||
                getValues("endEventType") === ""
              }
              name="endByVal"
              handleSetValue={setValue}
              watch={watch}
              showCalendarIcon={false}
              customClass="endByDate"
              format={DATE_FORMAT.DATE_MONTH_FORMAT}
              placeholder="Select a date"
              setMaxDate={true}
            />
          </div>
        </div>

        <div className="footer-note d-flex gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-info-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
          </svg>
          Kindly note, you can create a listing for a maximum of 365 days or one
          year.
        </div>
      </>
    );
  };

  return (
    <div className="WeeklyListingWrap mt-2">
      <CustomRadio
        name="eventType"
        watch={watch}
        handleSetValue={handleEventTypeChange}
        radioData={radioData}
      />

      <div className=" mb-4 mt-2">
        <MultiDatePicker
          name="eventDate"
          watch={watch}
          label={watch("eventDate").length > 0 ? watch("eventType") === radioData[1]?.value ? "Select the event start date" : "Select the event date(s)" : ""}
          placeholder={watch("eventType") === radioData[1]?.value ? "Select the event start date" : "Select the event date(s)"}
          format={DATE_FORMAT.DATE_MONTH_FORMAT}
          special={true}
          setMaxDate={watch("eventType") === radioData[1]?.value}
          setMinDate={true}
          handleSetValue={customEventDate}
        />
      </div>

      {watch("eventType") === radioData[1]?.value
        ? renderRecurringFields()
        : ""}
    </div>
  );
}
