import React, { useState } from "react";
import "./style.card.scss";
import AmountTag from "./AmountTag";
import Avatar from "./Avatar";
import MediaCarousel from "./MediaCarousel";
import ListingTypeTag from "./ListingTypeTag";
import DistanceTag from "./DistanceTag";
import ShareTag from "./ShareTag";
import FavoriteTag from "./FavoriteTag";
import { gettingEventDate } from "../../../constants/helperConnectUser";
import ShareModal from "../ShareModal";
import { makeDeleteCall, makePostCall } from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import { showToast } from "../../../utils";
import { useNavigate } from "react-router-dom";
import JoinTag from "./JoinTag";

type Props = {
  [key: string]: any;
};

const EventListingCard = (props: Props) => {
  const { data = null, handleEventActionCallback = () => null } = props;
  const [toggleShare, setToggleShare] = useState(false);
  const [shareModalContent, setShareModalContent] = useState(null);
  const navigate = useNavigate();

  const [isActionRunning, setActionRunning] = useState(false);

  const handleCloseShareModal = () => {
    setToggleShare(false);
    setShareModalContent(null);
  };

  const handleShareClick = (item) => {
    setShareModalContent({
      link: item?.dynamicLink,
      title: item?.title,
      media: item?.photos[0] || "",
      address:
        item?.scheduleDetails[0]?.address?.address ||
        item?.scheduleDetails?.address?.address ||
        "",
      listingType: item?.listingType,
    });
    setTimeout(() => {
      setToggleShare(true);
    }, 0);
  };

  const handleFavorite = async (id) => {
    if (!isActionRunning) {
      setActionRunning(true);
      const apiPayload = {
        listing: id,
      };

      if (data?.isFavourite) {
        makeDeleteCall({ url: apiConfig.favourite_listing, apiPayload }).then(
          (res) => {
            handleEventActionCallback(id, "fav");
            showToast(res.status.message, "success");
            setTimeout(() => {
              setActionRunning(false);
            }, 0);
          }
        );
      } else {
        makePostCall({ url: apiConfig.favourite_listing, apiPayload }).then(
          (res) => {
            handleEventActionCallback(id, "fav");
            showToast(res.status.message, "success");
            setTimeout(() => {
              setActionRunning(false);
            }, 0);
          }
        );
      }
    }
  };

  return (
    <>
      <div
        className="event_card_outer"
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/listing-two/${data?.id}`, {
            replace: true,
          });
        }}
      >
        <div className="media_container">
          <MediaCarousel media={data?.photos} />

          {data?.listingType !== "C" ? (
            <div className="amt_layer">
              <AmountTag amount={data?.price} />
            </div>
          ) : (
            <div className="join_layer">
              <JoinTag />
            </div>
          )}
          <div className="listing_type_layer">
            <ListingTypeTag virtual={data?.listingEventType === "V"} />
          </div>
          {data?.listingEventType !== "V" && (
            <div className="distance_layer">
              <DistanceTag distance={data?.distance} />
            </div>
          )}
          <div className="favorite_layer">
            <FavoriteTag
              active={data?.isFavourite}
              onClick={(e) => {
                e.stopPropagation();
                handleFavorite(data?.id);
              }}
            />
          </div>
          <div className="share_layer">
            <ShareTag
              onClick={(e) => {
                e.stopPropagation();
                handleShareClick(data);
              }}
            />
          </div>
        </div>
        <div className="event_details_section">
          <div className="title_section">{data?.title}</div>
          <div className="stamp_wrap">
            {data?.listingEventType !== "V" ? (
              <div className="stamp_section">
                <img
                  className="stamp_img"
                  src="/static/connect/location.svg"
                  alt=""
                />
                <div className="sub_details">
                  {data?.scheduleDetails[0]?.address?.address ||
                    data?.scheduleDetails?.address?.address ||
                    "NA"}
                </div>
              </div>
            ) : (
              <div style={{ minHeight: "18px" }} />
            )}
            {data?.listingType !== "C" ? (
              <div className="stamp_section">
                <img
                  className="stamp_img"
                  src="/static/connect/calendar.svg"
                  alt=""
                />
                <div className="sub_details">
                  {gettingEventDate(
                    data?.events?.[0]?.date,
                    data?.events?.[0]?.startTime,
                    data?.events?.[0]?.endTime
                  )}
                </div>
              </div>
            ) : (
              <div style={{ minHeight: "18px" }} />
            )}
          </div>
          <div className="author_section">
            <Avatar profile={data?.createdBy?.profilePicture} />
            <div className="author_name">
              {"Event by "}
              <span style={{ fontWeight: 500 }}>{data?.createdBy?.name}</span>
            </div>
          </div>
        </div>
      </div>
      {toggleShare && (
        <ShareModal
          show={toggleShare}
          handleClose={handleCloseShareModal}
          shareData={shareModalContent}
        />
      )}
    </>
  );
};

export default EventListingCard;
