import React from "react";
import { compressLargeNumber } from "../../../constants/helperConnectUser";

type Props = {
  [key: string]: any;
};

const AmountTag = (props: Props) => {
  return (
    <div className="amount_wrap">{`AUD ${compressLargeNumber(
      Number(props?.amount) || 0
    )}`}</div>
  );
};

export default AmountTag;
