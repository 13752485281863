import React, { useEffect, useRef } from "react";
import FlaggedUserCard from "./FlaggedUserCard";
import ConnectUserCard from "./ConnectUserCard";
import { CONNECTION_TYPE } from "../ConnectConstants";
import "./MapViewStyle.scss";
import { EventsMapCard } from "../../Dashboard/Event/EventsMapCard";

type Props = {
  multipleEventData?: any[];
  mode?: string;
  show?: any;
  hide?: any;
};

const MapViewMultiCardLayout = ({
  multipleEventData = [],
  mode,
  show,
  hide = () => null,
}: Props) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        hide(false); // Close the modal
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  return (
    <div className="layout_map_view_card" ref={modalRef}>
      <div className="map_view_card_wrap">
        {multipleEventData.map((val, index) => {
          return (
            <div key={index}>
              {mode === "event" ? (
                <EventsMapCard
                  item={val}
                  showEvent={show}
                  onClickOutside={() => {
                    // hide(false);
                    return;
                  }}
                />
              ) : mode === CONNECTION_TYPE.Flagged ? (
                <FlaggedUserCard connectionData={val} />
              ) : (
                <ConnectUserCard
                  connectionData={val}
                  mode={mode}
                  isScrollable={false}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MapViewMultiCardLayout;
