import React, { useEffect, useRef, useState } from "react";
import "./style.scss";

type Props = {
  [key: string]: any;
};

const DobField = (props: Props) => {
  const daysOption = Array.from(
    { length: 31 },
    (_, i) => i + 1
    // String(i + 1).padStart(2, "0")
  );
  const monthsOption = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const yearsOption = Array.from(
    { length: currentYear - 1899 },
    (_, i) => currentYear - i
  );

  const { watch, setValue, isLabel = true } = props;

  const InputDay = ({ name, label = "", value, setValue, option = [] }) => {
    const [isDropDownActive, setIsDropdownActive] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownActive(false);
      }
    };

    useEffect(() => {
      if (isDropDownActive) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isDropDownActive]);

    return (
      <div className="custom_select_wrap" ref={dropdownRef} key={name}>
        <div
          className={"input_container_main"}
          onClick={() => setIsDropdownActive(true)}
        >
          {/* {label && <label className="dob_input_label">{label}</label>} */}
          <input
            name={name}
            type="text"
            className="input_type"
            value={value || ""}
            placeholder={label}
            readOnly
          />
          <img
            className="dow_expand_icon"
            src="/static/arrow/expandGrey.svg"
            alt=""
          />
        </div>
        {isDropDownActive && (
          <div className="dob_dropdown_container">
            {option?.map((val) => (
              <div
                className={`drop_options ${
                  value === val ? "selected_option" : ""
                }`}
                onClick={() => setValue(name, val)}
              >
                {val}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="dob_container_wrap">
      {isLabel && <div className="title_dob">Date Of Birth</div>}
      <div className="dob_input_container">
        <div style={{ maxWidth: "120px" }}>
          <InputDay
            name="dobDate"
            label="Day"
            option={daysOption}
            value={Number(watch("dobDate"))}
            setValue={setValue}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <InputDay
            name="dobMonth"
            label="Month"
            option={monthsOption}
            value={watch("dobMonth")}
            setValue={setValue}
          />
        </div>
        <div style={{ maxWidth: "120px" }}>
          <InputDay
            name="dobYear"
            label="Year"
            option={yearsOption}
            value={Number(watch("dobYear"))}
            setValue={setValue}
          />
        </div>
      </div>
    </div>
  );
};

export default DobField;
