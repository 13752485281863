import React, { useEffect, useState } from "react";
import "./style.scss";
import OutlineButton from "../../global/OutlineButton";

const PaymentClose = () => {
  const [secondsLeft, setSecondsLeft] = useState(5);
  useEffect(() => {
    const countdown = setInterval(() => {
      setSecondsLeft((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (secondsLeft < 0) {
      setTimeout(() => {
        window.close();
      }, 0);
      clearInterval(countdown);
    }
    return () => {
      clearInterval(countdown);
    };
  }, [secondsLeft]);

  return (
    <div className="payment_layout">
      <div className="logo_container_pay">
        <img
          src="/static/logo/logoGif.gif"
          alt=""
          style={{
            width: "50px",
            aspectRatio: "1/1",
          }}
        />
        <img src="/static/logo/logoBasic.svg" alt="" />
      </div>

      {secondsLeft < 1 ? (
        <div>Please manually close the window.</div>
      ) : (
        <div>
          Please close the window, or it will automatically close in{" "}
          {secondsLeft} seconds.
        </div>
      )}
      {secondsLeft > 0 &&
      <OutlineButton onClick={() => window.close()} text="CLOSE WINDOW" />}
    </div>
  );
};

export default PaymentClose;
