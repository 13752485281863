import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  MarkerClusterer,
} from "@react-google-maps/api";
import { googleKey } from "../../../utils/Constants";
import { EventsMapCard } from "./EventsMapCard";
import { showToast } from "../../../utils";
import MapViewMultiCardLayout from "../../Connect/ConnectComponents/MapViewMultiCardLayout";

const EventsMapContainer = (props) => {
  const [showMultipleEvent, setShowMultipleEvent] = useState(false);
  const [multipleEventData, setMultipleEventData] = useState([]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleKey,
  });
  const [, setMap] = React.useState(null);
  const [center, setCenter] = useState<any>({
    lat: -31.397,
    lng: 151.644,
  });

  const [cordNew, setCordsNew] = useState([]);
  const [enableSelfMarker, setEnableSelfMarker] = useState(false);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const fetchCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setEnableSelfMarker(true);
        // setCurrentLat(position.coords.latitude);
        // setCurrentLng(position.coords.longitude);
        // setIslocationAccessible(true);
      },
      (err) => {
        // setIslocationAccessible(false);
        showToast(
          "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
          "error"
        );
      }
    );
  };
  useEffect(() => {
    let temp_array = [];
    let temp_storage = [];
    setCenter({
      lat: props.latitude || -25.397,
      lng: props.longitude || 134.644,
    });
    props.eventMap.map((item, index) => {
      if (item.coordinates) {
        if (
          temp_storage.includes(item.coordinates[0] + "-" + item.coordinates[1])
        ) {
          let temp_item = { ...item };
          var lat = item.coordinates[0];
          var lng = item.coordinates[1];
          temp_item.coordinates = [lat, lng];
          temp_array.push(temp_item);
        } else {
          temp_storage.push(item.coordinates[0] + "-" + item.coordinates[1]);
          temp_array.push(item);
        }
      }
    });
    if (props.distance != "5000") {
      setTimeout(() => {
        fetchCurrentLocation()
      }, 1000)
    }
    setCordsNew([...temp_array]);
  }, [props.latitude]);

  const [showEvent, setShowEvent] = useState(false);
  const createRadiusSelectorControl = () => {
    const radiusSelectorDiv = document.createElement("div");
    radiusSelectorDiv.className = "radius-selector";
    radiusSelectorDiv.innerHTML = `
      <span><img alt="" src ="/static/mapicon.svg"></img> </span>
      <select class="radiusSelect">
        <option value="5000" selected >5 KM</option>
        <option value="10000">10 KM</option>
        <option value="15000">15 KM</option>
        <option value="20000">20 KM</option>
      </select>
    `;

    radiusSelectorDiv
      .querySelector(".radiusSelect")
      .addEventListener("change", (e) => {
        // const selectedRadius = e.target.value;
        // this.setState({ radius: selectedRadius });
        // this.props.onRadiusChange(selectedRadius);
      });

    // window.google.maps.controls[google.maps.ControlPosition.TOP_RIGHT].push(
    //   radiusSelectorDiv
    // );
  };

   const handleClusterClick = (cluster) => {
     const markers = cluster.getMarkers();
     const markerData = markers.map((marker) => marker.get("customData"));
     setMultipleEventData(markerData);
     setTimeout(() => {
       setShowMultipleEvent(true);
     }, 0);
   };

   const handleSingleClusterClick = (item) => {
     setMultipleEventData([item]);
     setTimeout(() => {
       setShowMultipleEvent(true);
     }, 0);
   };

  return (
    <>
      {isLoaded ? (
        <>
          <div
            style={{ position: "relative" }}
            className="events-map-container"
          >
            <div
              className="create-listing-btn-2"
              style={{
                position: "absolute",
                bottom: "30px",
                display: "flex",
                justifyContent: " flex-end",
                right: "0",
                left: "0",
                margin: "0 auto",
                width: "300px",
                zIndex: "10",
                cursor: "pointer",
              }}
              onClick={fetchCurrentLocation}
            >
              <img src="/static/GoCurrentLocation.svg" alt="" />
            </div>

            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "calc(100vh - 100px)",
              }}
              zoom={6}
              onLoad={onLoad}
              onUnmount={onUnmount}
              center={center}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: true,
                panControl: false,
                rotateControl: false,
                streetViewControl: false,
                minZoom: 2,
                maxZoom: 18,
                zoomControlOptions: {
                  position: google.maps.ControlPosition.TOP_LEFT,
                },
              }}
            >
              <MarkerClusterer
                minimumClusterSize={3}
                onClick={handleClusterClick}
                options={{ zoomOnClick: false }}
              >
                {(clusterer) => (
                  <div>
                    {enableSelfMarker && (
                      <Marker
                        key={"selft"}
                        position={{
                          lat: center.lat,
                          lng: center.lng,
                        }}
                        icon={{
                          url: "static/CurentMarker.svg",
                          scaledSize: new window.google.maps.Size(36, 36),
                        }}
                      />
                    )}
                    {cordNew.map(
                      (item, index) =>
                        item.coordinates && (
                          <Marker
                            clusterer={clusterer}
                            key={index}
                            position={{
                              lat: item.coordinates[1],
                              lng: item.coordinates[0],
                            }}
                            onClick={() => {
                              handleSingleClusterClick(item);
                            }}
                            icon={{
                              url: "/static/chat-theme-2.svg",
                              scaledSize: new window.google.maps.Size(36, 36),
                            }}
                            onLoad={(marker) => {
                              marker.set("customData", item);
                            }}
                          />
                        )
                    )}
                  </div>
                )}
              </MarkerClusterer>
            </GoogleMap>

            {showEvent && (
              <div
                style={{ position: "absolute", bottom: "20%", left: "40%" }}
                className="custom-card-position"
              >
                <EventsMapCard
                  item={showEvent}
                  showEvent={showEvent}
                  onClickOutside={() => {
                    setShowEvent(false);
                  }}
                />
              </div>
            )}

            {showMultipleEvent && (
              <MapViewMultiCardLayout
                multipleEventData={multipleEventData}
                mode={'event'}
                show={showMultipleEvent}
                hide={setShowMultipleEvent}
              />
            )}

          </div>
        </>
      ) : (
        <div>Loading....</div>
      )}
    </>
  );
};

export default EventsMapContainer;
